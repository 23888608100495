import React, {useEffect, useState} from 'react'
import Layout from '../components/layout'
import {RoamPage} from "roam-export"

import SectionHeading from '../components/section-heading'

import {UploadForm, withAuth} from "@roam-garden/landscaping-toolbox"

import '../common/amplify-wrapper' // to configure amplify
import {StickyProvider} from '../contexts/app/app.provider'
import {Box, Card, Container, Divider, Flex, Heading, Input, Label, Link as A} from "theme-ui"
import {SubscriptionModal} from "../components/subscription-modal"

import {navigate} from "gatsby"

const App = () => {
  const [dbFile, setDbFile] = useState<File | undefined>(null)
  const [roamData, setRoamData] = useState<RoamPage[] | undefined>(null)

  useEffect(() => {
    (async () => {
      if (dbFile) {
        setRoamData(JSON.parse(await dbFile.text()))
      }
    })()
  }, [dbFile])

  function FileSelectionCard() {
    const hiddenInput = "hiddenInput"
    return <Flex sx={{
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    }}>
      <Heading>
        <A href="https://help.roam.garden/?stackedPages=%2FRoam-Garden-plugin-setup"
           target="_blank"
        >Instructions to set up roam/js plugin for publishing your garden directly from Roam</A>
      </Heading>

      <Divider sx={{width: "30%", my: "2em"}}/>

      <Card
        sx={{
          display: "flex",
          width: "100%",
        }}
        onDrop={e => {
          e.stopPropagation()
          e.preventDefault()

          setDbFile(e.dataTransfer?.files[0])
        }}
        onDragOver={e => e.preventDefault()}
      >
        <Label
          htmlFor={hiddenInput}
          sx={{
            display: "flex",
            height: "50vh",
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          <Heading
            sx={{
              fontSize: "3em",
              margin: "auto",
              color: "secondary",
            }}
          >
            Drag & drop your
            <A
              href="https://help.roam.garden/?stackedPages=%2FHow-to-export-your-Roam-Graph"
              target="_blank"
              sx={{mx: ".2em"}}
            >
              graph JSON</A>
            here
            <br/> <br/>
            Or click to select it from the disk
          </Heading>
        </Label>

        <Input
          id={hiddenInput}
          type="file"
          accept="application/json"
          onChange={e => setDbFile(e.target.files?.[0])}
          sx={{display: "none"}}
        />
      </Card>
    </Flex>
  }

  return (
    <StickyProvider>
      <SubscriptionModal/>
      <Layout>
        <Box as="section" id="upload" sx={styles.section}>
          <Container>
            <SectionHeading title="Create or update your garden"/>

            {roamData ?
              <UploadForm
                allPageNames={roamData.map(it => it.title)}
                roamDataSupplier={() => roamData}
                showModal={false}
                onSubmit={() => navigate("/upload-success")}
              /> :
              <FileSelectionCard/>}
          </Container>
        </Box>
      </Layout>
    </StickyProvider>)
}

export default withAuth(App)

const styles = {
  section: {
    pt: "140px",
    pb: [12, null, null, null, null, 15],
  },
}
