import React, {useEffect, useState} from "react"
import ReactModal from "react-modal"
import {Box, Button, Flex, Heading, Spinner, Text} from "theme-ui"
import {
    CheckoutPrice,
    currentUserHasExpiredSubscription,
    currentUserHasValidSubscription,
    redirectToCheckout,
} from "../common/billing"
import {Price, StripeData} from "../config"
import {commonStyles} from "../common/styles"
import {useLocalState} from "@roam-garden/landscaping-toolbox"

const {earlyBirdMonthly, earlyBirdYearly} = StripeData

const priceToProps = (price: Price): PriceItemProps => ({
    name: price.name,
    yearly: `$${price.valuePerMonth * 12} / year`,
    monthly: `$${price.valuePerMonth} / month`,
    checkoutPrice: {id: price.id},
})

export const SubscriptionModal = () => {
    const [modalOpen, setModalOpen] = useLocalState("lastSubscriptionState", true)
    const [hasExpiredSubscription, setHasExpiredSubscription] = useLocalState("hasExpiredSubscription", false)

    useEffect(() => {
        (async () => {
            const hasSubscription = await currentUserHasValidSubscription()
            setModalOpen(!hasSubscription)
            if (hasSubscription) trackConversion()

            setHasExpiredSubscription(await currentUserHasExpiredSubscription())
        })()
    })

    return <ReactModal isOpen={modalOpen}
                       shouldCloseOnEsc={false}
                       style={{
                           overlay: {
                               marginTop: "70px",
                               display: "flex",
                           },
                           content: {
                               margin: "auto",
                               position: "unset",
                           },
                       }}
    >
        <Box sx={{
            flexDirection: "column",
            alignItems: "center",
            display: "inline-flex",
        }}>
            <Heading as="h2">Subscribe with early-bird pricing (40% off)</Heading>
            {/*<button onClick={() => setModalOpen(false)}>Close Modal</button>*/}
            <PriceItem {...priceToProps(earlyBirdYearly)}/>
            <PriceItem {...priceToProps(earlyBirdMonthly)}/>
            {!hasExpiredSubscription && <PriceItem
                name="7-Day Free trial (with monthly pricing after)"
                checkoutPrice={{
                    id: earlyBirdMonthly.id,
                    isTrial: true,
                }}
            />}
        </Box>
    </ReactModal>
}

function trackConversion() {
    // Google Ads conversion tracking
    // not entirely sure that this is a great place =\
    typeof window !== "undefined" &&
    window.gtag('event', 'conversion', {'send_to': 'AW-399805774/bOO5CI6B2PwBEM6a0r4B'});
}

interface PriceItemProps {
    name: string
    yearly?: string
    monthly?: string
    checkoutPrice: CheckoutPrice
}

const PriceItem = ({name, monthly, yearly, checkoutPrice}: PriceItemProps) => {
    const [isLoading, setIsLoading] = useState(false)

    return <Button
        sx={styles.priceButton}
        onClick={async () => {
            setIsLoading(true)
            await redirectToCheckout(checkoutPrice)
        }}
    >
        <Flex sx={styles.buttonContent}>
            <Text sx={{fontSize: "1.5rem"}}>{name}</Text>

            {monthly && <Text>{monthly}</Text>}
            {yearly && <Text>{yearly}</Text>}

            {isLoading && <Spinner sx={commonStyles.spinner.inButton}/>}
        </Flex>
    </Button>
}

const styles = {
    priceButton: {
        marginTop: "1em",
        width: "100%",
        maxWidth: "70vw",
        minWidth: "600px",
        lineHeight: "4em",
        position: "relative",
    },
    buttonContent: {
        justifyContent: "space-between",
        "> :only-child": {
            marginLeft: "auto",
            marginRight: "auto",
        },

    },
}
